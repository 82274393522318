<script setup lang="ts">
import anime from 'animejs'
// import { gsap } from 'gsap'

import { PROJECT_HEADER_HEIGHT } from '~/app.vue'
import type { Project } from '~/types/project'

import { PROJECT_PAGE_CLASS } from '../helper/Project.vue'
import {
  PROJECT_CONTENT_CLASS,
  PROJECT_TRANSITION_BACKGROUND_CLASS,
} from '../sections/ProjectSection.vue'

defineProps<{
  nextProject: Project
}>()

const tiltRef = ref<ComponentPublicInstance | null>(null)
const containerRef = ref<HTMLDivElement | null>(null)
const textRef = ref<HTMLDivElement | null>(null)

const clipPathAnimation = reactive({
  topLeftYFactor: 1,
})

// onMounted(() => {
//   const tl = gsap.timeline()

//   tl.to(tiltRef.value!.$el, {
//     translateY: '-100px',
//     background: 'red',
//     duration: 1000,
//     ease: 'power2.inOut',
//   })
//   tl.play()

//   console.log('play')
// })

const transitioning = ref(false)

function transition(href: string) {
  if (transitioning.value) return

  transitioning.value = true
  disablePageTransition.value = true

  const el = tiltRef.value!.$el as HTMLElement
  const elRect = el.getBoundingClientRect()

  el.style.transform = `translateX(${elRect.x}px) translateY(${elRect.y}px)`
  el.style.marginTop = '0'
  el.style.marginBottom = '0'
  el.style.zIndex = '-10'
  el.style.position = 'fixed'
  el.style.top = '0'
  el.style.height = `${elRect.height}px`
  el.style.width = `${elRect.width}px`
  el.classList.add(PROJECT_TRANSITION_BACKGROUND_CLASS)
  document.body.append(el)

  preloadRouteComponents(href)

  // const factor = 1

  // push elements above away
  anime({
    targets: `.${PROJECT_PAGE_CLASS}`,
    translateY: '-100vh',
    duration: 800,
    easing: 'easeInOutSine',
  })

  const text = textRef.value!.cloneNode(true) as HTMLElement
  const textElRect = textRef.value!.getBoundingClientRect()
  text.style.zIndex = '20'
  text.style.position = 'fixed'
  document.body.append(text)

  anime({
    targets: text,
    translateY: [textElRect.y, -textElRect.height],
    easing: 'easeInSine',
    duration: 450,
    complete: () => {
      text.remove()
    },
  })

  const topLeftYFactorCssVar = useCssVar('--top-left-y-factor', el)
  watchEffect(() => {
    topLeftYFactorCssVar.value = `${clipPathAnimation.topLeftYFactor}`
  })

  anime({
    targets: clipPathAnimation,
    topLeftYFactor: [1, 0],
    duration: 700,
    easing: 'easeInOutSine',
  })
  anime
    .timeline({
      targets: el,
      easing: 'easeInOutSine',
      complete: async () => {
        el.style.transition = 'height ease-in-out 1s'

        const nuxt = useNuxtApp()
        nuxt.hooks.hookOnce('page:finish', () => {
          console.debug('page:finish')
          disablePageTransition.value = false
          transitioning.value = false

          // fade in text
          anime({
            targets: document.querySelectorAll(`.${PROJECT_CONTENT_CLASS}`),
            opacity: [0, 1],
            easing: 'easeInOutSine',
            duration: 1000,
          })
        })

        await navigateTo(href)

        if (el.parentElement) el.parentElement.style.backgroundColor = ''
        el.style.height = '100%'
        el.style.width = '100%'
      },
    })
    .add({
      translateY: 0,
      duration: 700,
    })
    .add(
      {
        height: window.innerHeight,
        duration: 500,
      },
      0,
    )
    .add(
      {
        height: PROJECT_HEADER_HEIGHT.value,
        duration: 500,
        easing: 'easeOutSine',
      },
      600,
    )
}
</script>

<template>
  <div
    ref="containerRef"
    class="group h-[calc(var(--tilt-height-container)*2+3rem)] overflow-hidden pt-tilt-container sm:h-[calc(var(--tilt-height-container)*2+1rem)]"
  >
    <ElbworxLink v-slot="{ href }" :href="`/projekt/${nextProject.slug}/`" custom>
      <a
        :href="href"
        class="relative block transition-transform duration-500 ease-in-out group-hover:-translate-y-[calc(var(--tilt-height-container)*0.6)]"
        @click.prevent="() => transition(href)"
      >
        <div :key="transitioning.toString()" class="contents">
          <Tilt v-if="!transitioning" ref="tiltRef" class="relative h-[--project-header-height]">
            <BackgroundMedia
              :background-color="nextProject.primary_color"
              :background-image="nextProject.header.background.image"
              :background-video="nextProject.header.background.video"
              :background-position="nextProject.header.background.position"
              :black-tint="!nextProject.header.background.black_tint_off"
            />
          </Tilt>
        </div>
        <div
          v-show="!transitioning"
          ref="textRef"
          :key="$route.path"
          class="absolute max-sm:inset-0 max-sm:text-center sm:right-0 sm:top-0"
        >
          <Padding>
            <PageHeadline class="-translate-y-1/4 !text-6xl sm:pr-16 sm:!text-7xl md:!text-8xl">
              noch eins
            </PageHeadline>
          </Padding>
        </div>
      </a>
    </ElbworxLink>
  </div>
</template>
