<script lang="ts" setup>
import { PROJECT_PAGE_CLASS } from '~/components/helper/Project.vue'
import { allProjects } from '~/pages/projekte.vue'
import { projectKey } from '~/types/inject'

import colors from '#tailwind-config/theme/colors'

defineSlots<{
  default: void
}>()

const project = inject(projectKey)

const projectIndex = computed(() =>
  allProjects.findIndex(([p]) => p.slug === project?.value?.value.slug),
)
const nextProject = computed(() => allProjects[(projectIndex.value + 1) % allProjects.length][0])

useSeoMeta({
  themeColor: colors['elbworx-yellow'],
})

const route = useRoute()
</script>

<template>
  <div class="flex min-h-screen flex-col text-elbworx-black">
    <Navbar />
    <div>
      <slot />
    </div>
    <ScrollToTopButton :class="PROJECT_PAGE_CLASS" />

    <PageFooter v-if="!route.name?.toString().startsWith('projekt-')" />
    <NextProject v-else :next-project />
  </div>
</template>
